<template>
  <svg :width="width" :height="height" viewBox="0 0 44.614783 47" xmlns="http://www.w3.org/2000/svg">
    <g id="g3" transform="translate(-0.47343063,-1)">
      <path fill="#2658C6" d="m 11.91,13.88 -1.13,12.88 c -0.16,1.78 1.16,3.22 2.94,3.22 h 25.75 c 1.78,0 3.35,-1.44 3.5,-3.22 L 45.08,2.62 C 45.17,1.72 44.51,1 43.62,1 42.97,1 42.38,1.38 42.08,1.93 L 37.91,9.7 C 37.6,10.24 37,10.65 36.36,10.65 H 15.42 c -1.78,0.01 -3.35,1.45 -3.51,3.23 z" />
      <path fill="#002F8E" d="m 33.66,35.12 1.13,-12.88 c 0.16,-1.78 -1.16,-3.22 -2.94,-3.22 H 6.09 c -1.78,0 -3.35,1.44 -3.5,3.22 L 0.48,46.39 C 0.4,47.28 1.06,48 1.95,48 2.6,48 3.19,47.62 3.49,47.07 L 7.66,39.3 c 0.31,-0.54 0.91,-0.95 1.55,-0.95 h 20.94 c 1.78,-0.01 3.35,-1.45 3.51,-3.23 z" />
      <path fill="#00193C" d="m 34.78,22.25 c 0.16,-1.78 -1.16,-3.22 -2.94,-3.22 H 11.46 l -0.68,7.73 c -0.16,1.78 1.16,3.22 2.94,3.22 H 34.1 Z" />
   </g>
    <g id="g8" transform="translate(-0.47343063,-1)"></g>
</svg>
</template>
<script>
export default {
  name: "DuotalkIcon",
  props: {
    width: Number,
    height: Number,
  },
}
</script>
