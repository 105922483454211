<!-- =========================================================================================
    File Name: CustomSkeleton.vue
========================================================================================== -->

<template>
  <span class="skeleton-main skeleton-o-media" :class="customClass">
    <span class="flex w-full">
      <span class="skeleton-box" :style="`width: ${size}`"></span>
    </span>
  </span>
</template>

<script>
export default {
  name: "CustomSkeleton",
  props: {
    customClass: {
      type: [String, Array, Object],
    },
    size: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.skeleton-main {
  display: inline;
}

.skeleton-o-media {
  display: inline-flex;
}

.flex {
  display: inline-flex;
}

.w-full {
  width: 100%;
}
</style>
