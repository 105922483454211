import { io } from "socket.io-client";

const URLAttendance = `${process.env.VUE_APP_WS_URL}/attendance`;
const socket = io(URLAttendance, {
  withCredentials: true, autoConnect: false, transports: ['websocket'], multiplex: false, forceNew: true, upgrade: false, reconnection: true, timeout: 80000, reconnectionDelay: 1000,
});

/*socket.onAny((event, ...args) => {
  //console.log('GLOBAL onAny', new Date(), event, args);
});
socket.onAnyOutgoing((event, ...args) => {
  //console.log('GLOBAL onAnyOutgoing', new Date(), event, args);
});*/



export default socket;
