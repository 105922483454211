<template>
  <div
    class="rounded-full"
    :class="[online ? 'bg-success' : 'bg-grey', `h-${size}`, `w-${size}`]"
  ></div>
</template>

<script>
export default {
  name: "OnlineCircleComponent",
  props: {
    online: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 4,
    },
  },
};
</script>

<style lang="scss" scoped></style>
