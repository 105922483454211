<template>
  <div class="flex">
    <div
      class="card-left flex"
      :style="`background-color: ${cardColor}`"
      :class="!cancelable ? 'card-right' : ''"
    >
      <i class="vs-icon feather icon icon-moon mr-1"></i>
      <p class="text-xs">
        {{ time }}
      </p>
    </div>

    <div
      v-if="cancelable"
      class="card-right card-cancelable"
      :style="`background-color: ${cardColor}`"
      onMouseOver="this.style.backgroundColor = '#ff0000ea'"
      :onMouseOut="`this.style.backgroundColor = '${cardColor}'`"
      @click="$emit('onCancel')"
    >
      <i class="vs-icon feather icon icon-x mr-1"></i>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "postponed-conversation-tag",
  data() {
    return {
      cardColor: "#ffa600ea",
    };
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    complete: {
      type: Boolean,
      required: false,
    },
    cancelable: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    time() {
      moment.locale("pt_br");

      const conversationDate = moment(this.date);
      const currentDate = moment();
      let result = conversationDate.format("HH:mm");

      if (currentDate.diff(conversationDate) >= 0) {
        result = conversationDate.format("DD MMM");
        this.setCardColor("#ff0000ea");
      } else {
        this.setCardColor("#ffa600ea");
      }

      if (this.complete) {
        if (conversationDate.dayOfYear() === currentDate.dayOfYear()) {
          result = `${this.$t("Today")}, ${conversationDate.format("HH:mm")}`;
        }

        if (conversationDate.dayOfYear() > currentDate.dayOfYear()) {
          if (conversationDate.dayOfYear() - currentDate.dayOfYear() === 1) {
            result = `${this.$t("Tomorrow")}, ${conversationDate.format(
              "HH:mm"
            )}`;
          } else {
            result = conversationDate.format("DD MMM, HH:mm");
          }
        }
      } else {
        if (conversationDate.dayOfYear() === currentDate.dayOfYear()) {
          result = this.$t("Today");
        }

        if (conversationDate.dayOfYear() > currentDate.dayOfYear()) {
          if (conversationDate.dayOfYear() - currentDate.dayOfYear() === 1) {
            result = this.$t("Tomorrow");
          } else {
            result = conversationDate.format("DD MMM");
          }
        }
      }

      return result;
    },
  },
  methods: {
    setCardColor(color) {
      this.cardColor = color;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: white;

  padding: 0.5rem 0.3rem;
}

.card-right {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.card-cancelable {
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.2rem;
}
</style>
