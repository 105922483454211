<template>
  <div>
    <svg v-if="filled" xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="88.428 12.828 107.543 207.085">
      <path :fill="color" d="M158.232 219.912v-94.461h31.707l4.747-36.813h-36.454V65.134c0-10.658 2.96-17.922 18.245-17.922l19.494-.009V14.278c-3.373-.447-14.944-1.449-28.406-1.449-28.106 0-47.348 17.155-47.348 48.661v27.149H88.428v36.813h31.788v94.461l38.016-.001z"></path>
    </svg>

    <svg v-else xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 24 24" fill="none" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
    </svg>
  </div>
</template>
<script>
export default {
  name: "FacebookIcon",
  props: {
    width: [Number, String],
    height: [Number, String],
    color: {
      type: String,
      required: false,
      default: "#3c5a9a",
    },
    filled: {
      type: Boolean,
      required: false,
      default: true,
    }
  }
}
</script>
